import * as Yup from "yup"
import { phoneRegex, standardRequiredText, shareList, dateRegex } from "../../constants"
import { parseDateString, switchDateFormat } from "../../utils/form-data"
import moment from "moment"

const addressFields = {
  address: Yup.string().required(standardRequiredText),
  town: Yup.string().required(standardRequiredText),
  county: Yup.string().required(standardRequiredText),
  postcode: Yup.string().required(standardRequiredText),
  country: Yup.string().required(standardRequiredText),
  address_from: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  address_to: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    ),
}

const nameChange = {
  surname_or_firstname: Yup.string(),
  new_name: Yup.string(),
  name_change_from: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    ),
  name_change_to: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    ),
}

export const validationSchema = Yup.object().shape({
  consultant_name: Yup.string().required(standardRequiredText),
  title: Yup.string().required(standardRequiredText),
  first_name: Yup.string().required(standardRequiredText),
  middle_name: Yup.string(),
  date_of_birth: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  surname: Yup.string().required(standardRequiredText),
  birth_nationality: Yup.string().required(standardRequiredText),
  county_of_birth: Yup.string().required(standardRequiredText),
  country_of_birth: Yup.string().required(standardRequiredText),
  town_of_birth: Yup.string().required(standardRequiredText),
  gender: Yup.string().required(standardRequiredText),
  mother_name: Yup.string().required(standardRequiredText),
  email: Yup.string()
    .email("Must be a valid email address in the format 'example@example.com'")
    .required(standardRequiredText),
  contact_number: Yup.string()
    .matches(phoneRegex, "Must be a valid phone number")
    .required(standardRequiredText),
  method_of_transport: Yup.array()
    .min(1)
    .required(standardRequiredText),
  nationality_changed: Yup.string().required(standardRequiredText),
  new_nationality: Yup.string(),
  date_arrived: Yup.string().when(['birth_nationality', 'nationality_changed', 'new_nationality'], {
    is: (birth_nationality, nationality_changed, new_nationality) =>
      (nationality_changed === "Yes" && new_nationality !== "United Kingdom") ||
      (nationality_changed === "No" && birth_nationality !== "United Kingdom"),
    then: Yup.string().matches(
      dateRegex,
      "Must be a valid date in the format dd/mm/yyyy"
    ).required(standardRequiredText),
  }),
  // share_code: Yup.string().when(['birth_nationality', 'date_arrived', 'nationality_changed', 'new_nationality'], {
  //   is: (birth_nationality, date_arrived, nationality_changed, new_nationality) =>
  //     (shareList.includes(birth_nationality && nationality_changed === "No") ||
  //       (nationality_changed === "Yes" && shareList.includes(new_nationality))) &&
  //     date_arrived &&
  //     switchDateFormat(date_arrived) <= new Date("12/31/2020"),
  //   then: Yup.string().required(standardRequiredText).max(9, "Max 9 Characters"),
  // }),
  passport_number: Yup.number().typeError("Must be a number"),
  passport_expiry_date: Yup.string().matches(
    dateRegex,
    "Must be a valid date in the format dd/mm/yyyy"
  ),
  passport_issued_date: Yup.string().matches(
    dateRegex,
    "Must be a valid date in the format dd/mm/yyyy"
  ),
  unspent_convictions: Yup.string().required(standardRequiredText),
  surname_changed: Yup.string().required(standardRequiredText),
  birth_first_name: Yup.string(),
  birth_surname: Yup.string(),
  birth_name_date: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    ),
  has_other_name: Yup.string().required(standardRequiredText),
  other_names: Yup.array().of(Yup.object().shape(nameChange)),
  addresses: Yup.array()
    .of(Yup.object().shape(addressFields))
    .min(1),
  national_insurance: Yup.string().required(standardRequiredText),
  national_insurance_number: Yup.string(),
  national_identity_card: Yup.string().required(standardRequiredText),
  nic_number: Yup.string(),
  passport: Yup.string().required(standardRequiredText),
  passport_number: Yup.string(),
  passport_country: Yup.string(),
  driving_licence: Yup.string().required(standardRequiredText),
  driving_licence_number: Yup.string(),
  driving_licence_country: Yup.string(),
  documents: Yup.array()
    .max(2, "Maximum of 2 documents")
    .required(standardRequiredText),
})
