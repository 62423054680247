export const initialValues = {
  consultant_name: "",
  title: "",
  first_name: "",
  middle_name: "",
  date_of_birth: "",
  surname: "",
  birth_nationality: "",
  date_arrived: "",
  // share_code: "",
  passport_number: "",
  passport_expiry_date: "",
  passport_issued_date: "",
  county_of_birth: "",
  country_of_birth: "",
  town_of_birth: "",
  gender: "",
  mother_name: "",
  email: "",
  contact_number: "",
  method_of_transport: [],
  nationality_changed: "",
  new_nationality: "",
  unspent_convictions: "",
  surname_changed: "",
  birth_first_name: "",
  birth_surname: "",
  birth_name_date: "",
  has_other_name: "",
  other_names: [
    {
      surname_or_firstname: "",
      new_name: "",
      name_change_from: "",
      name_change_to: "",
    },
  ],
  addresses: [
    {
      address: "",
      town: "",
      county: "",
      postcode: "",
      country: "",
      address_from: "",
      address_to: "",
    },
  ],
  national_insurance: "",
  national_insurance_number: "",
  national_identity_card: "",
  nic_number: "",
  passport: "",
  passport_number: "",
  passport_country: "",
  driving_licence: "",
  driving_licence_number: "",
  driving_licence_country: "",
  documents: [],
}
