import React, { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { Formik } from "formik"

import { LCard, Col2 } from "../style/containers"
import Layout from "../components/layout"
import SButton from "../components/button"
import Signature from "../components/Signature"
import Form from "../components/form/FormHelper"
import { initialValues } from "../components/HealthcareDBS"
import { FormikTabs } from "../components/form/tabs"
import { FormikText } from "../components/form/textarea"
import { FormikInput, Error, FormikDatepicker, FormikSelect } from "../components/form"
import { submitForm, handleSubmitResponse, FormatFormData, dateCheck, dateOfBirthCheck } from "../services"
import { validationSchema } from "../components/HealthcareDBS"
import { FileUpload } from "../components/fileUpload"
import { TagSelector } from "../components/form/tags"
import { countryList } from "../constants"

const HealthcareApplication = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      const formatData = {
        ...values,
        date_of_birth:
          dateCheck(values.date_of_birth),
        birth_name_date: dateCheck(values.birth_name_date),
        other_names: values.other_names.map(names => {
          return {
            ...names,
            name_change_from: dateCheck(names.name_change_from),
            name_change_to: dateCheck(names.name_change_to),
          }
        }),
        addresses: values.addresses.map(add => {
          return {
            ...add,
            address_from: dateCheck(add.address_from),
            address_to: dateCheck(add.address_to),
          }
        })
      }
      const formData = await FormatFormData(formatData, sigCanvas)
      let res = await submitForm(formData, "/healthcare-dbs")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  const addRow = async (
    rep_type,
    values,
    validateForm,
    setFieldTouched,
    setFieldValue
  ) => {
    const errors = await validateForm()
    const currentReps = values[rep_type]

    const rep_obj = initialValues.addresses[0]
    if (errors[rep_type]) {
      // touch all fields of last rep
      const fields = Object.keys(rep_obj)
      return fields.forEach(field => {
        const currentRep = currentReps.length - 1
        const fieldPath = `[${rep_type}][${currentRep}][${field}]`
        setFieldTouched(fieldPath)
      })
    } else {
      // add new rep
      setFieldValue(`[${rep_type}]`, [...currentReps, rep_obj])
    }
  }

  const removeRow = (
    rep_type,
    repIndexToRemove,
    values,
    setFieldTouched,
    setFieldValue
  ) => {
    const reps = values[rep_type]
    // filter out rep by index
    const updatedReps = reps.filter((_, idx) => idx !== repIndexToRemove)
    // clear any touched errors
    setFieldTouched(`[${rep_type}][${repIndexToRemove}]`, false)
    setFieldValue(`[${rep_type}]`, updatedReps)
  }

  const addRowName = async (
    rep_type,
    values,
    validateForm,
    setFieldTouched,
    setFieldValue
  ) => {
    const errors = await validateForm()
    const currentReps = values[rep_type]

    const rep_obj = initialValues.other_names[0]
    if (errors[rep_type]) {
      // touch all fields of last rep
      const fields = Object.keys(rep_obj)
      return fields.forEach(field => {
        const currentRep = currentReps.length - 1
        const fieldPath = `[${rep_type}][${currentRep}][${field}]`
        setFieldTouched(fieldPath)
      })
    } else {
      // add new rep
      setFieldValue(`[${rep_type}]`, [...currentReps, rep_obj])
    }
  }

  return (
    <Layout healthcare={true} emailNoCheck={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Healthcare Company Application Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            setFieldValue,
            values,
            isSubmitting,
            validateForm,
            setFieldTouched,
          }) => (
            <Form>
              <h1>DBS Information</h1>
              <Col2>
                <FormikInput
                  name="consultant_name"
                  type="text"
                  label="Consultant name"
                />
              </Col2>
              <h2>Personal information</h2>
              <Col2>
                <FormikSelect
                  label="Title"
                  name="title"
                  options={[
                    { value: "Mr", label: "Mr" },
                    { value: "Mrs", label: "Mrs" },
                    { value: "Miss", label: "Miss" },
                    { value: "Ms", label: "Ms" },
                    { value: "Other", label: "Other" },
                  ]}
                  colorTheme="healthcare"
                />
              </Col2>
              <Col2>
                <FormikInput name="first_name" type="text" label="First name" />
                <FormikInput
                  name="middle_name"
                  type="text"
                  label="Middle name"
                />
                <FormikInput name="surname" type="text" label="Surname" />
                <FormikDatepicker
                  name="date_of_birth"
                  placeholderText="Select a date"
                  label="Date of birth"
                  maxDate={dateOfBirthCheck()}
                />
                <FormikSelect
                  label="Birth nationality"
                  name="birth_nationality"
                  options={countryList}
                />
                <FormikInput
                  name="county_of_birth"
                  type="text"
                  label="County of birth"
                />
                <FormikInput
                  name="country_of_birth"
                  type="text"
                  label="Country of birth"
                />
                <FormikInput
                  name="town_of_birth"
                  type="text"
                  label="Town of birth"
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="gender"
                  label="Gender"
                  tabs={[
                    { name: "male", label: "Male" },
                    { name: "female", label: "Female" },
                    { name: "other", label: "Other" },
                  ]}
                  colorTheme="healthcare"
                />
                <FormikInput
                  name="mother_name"
                  type="text"
                  label="Mother's maiden name"
                />
                <FormikInput name="email" type="email" label="Email" />
                <FormikInput name="contact_number" type="tel" label="Mobile" />
                <TagSelector
                  label="What are your current methods of transport?"
                  name="method_of_transport"
                  options={["Bike", "Bus", "Car", "Walk", "Other"]}
                />
              </Col2>
              <FileUpload colorTheme="healthcare" />
              <h2>Personal history</h2>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="nationality_changed"
                  label="Has your nationality changed since birth?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.nationality_changed === "Yes" ? (
                  <FormikSelect
                    label="If yes please enter you new nationality"
                    name="new_nationality"
                    options={countryList}
                  />
                ) : (
                  <div></div>
                )}
                <FormikInput
                  name="date_arrived"
                  type="text"
                  label="UK Arrival Date"
                  disabled={
                    (values?.birth_nationality === "United Kingdom" &&
                      values?.nationality_changed === "No") ||
                    (values?.new_nationality === "United Kingdom")
                  }
                />
                {/* <FormikInput
                  name="share_code"
                  type="text"
                  label="Share Code"
                  disabled={
                    (values?.birth_nationality === "United Kingdom" &&
                      values?.nationality_changed === "No") ||
                    (values?.new_nationality === "United Kingdom")
                  }
                /> */}
                <FormikInput
                  name="passport_number"
                  type="text"
                  label="Passport Number"
                />
                <FormikInput
                  name="passport_expiry_date"
                  type="text"
                  label="Passport Expiry"
                />
                <FormikInput
                  name="passport_issued_date"
                  type="text"
                  label="Passport Issued"
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="unspent_convictions"
                  label="Do you have any convictions, cautions, reprimands or final warmings which would not be filtered in line with current guidance?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                <p>
                  * Please select YES if you have any unspent convictions. For
                  more information about whether convictions are considered
                  "Spent" or not, please see{" "}
                  <a
                    href="https://www.gov.uk/government/publications/dbs-filtering-guidance/dbs-filtering-guide"
                    target="_blank"
                  >
                    https://www.gov.uk/government/publications/dbs-filtering-guidance/dbs-filtering-guide
                  </a>
                </p>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="surname_changed"
                  label="Has your surname changed since birth?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
              </Col2>
              {values.surname_changed === "Yes" ? (
                <>
                  <p>If yes please enter fill in your name details below</p>
                  <Col2>
                    <FormikInput
                      name="birth_first_name"
                      type="text"
                      label="First name at birth"
                    />
                    <FormikInput
                      name="birth_surname"
                      type="text"
                      label="Surname at birth"
                    />
                    <FormikDatepicker
                      name="birth_name_date"
                      placeholderText="Select a date"
                      label="Used till"
                      maxDate={new Date()}
                    />
                  </Col2>
                </>
              ) : (
                ""
              )}
              <FormikTabs
                setFieldValue={setFieldValue}
                name="has_other_name"
                label="Have you ever been known by any other names?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.has_other_name === "Yes" ? (
                <>
                  {values
                    ? values.other_names.map((_, idx) => (
                      <React.Fragment key={`other_names${idx}`}>
                        <Col2>
                          <FormikTabs
                            setFieldValue={setFieldValue}
                            name={`other_names[${idx}].surname_or_firstname`}
                            label="Surname or firstname?"
                            tabs={[
                              { name: "firstname", label: "First name" },
                              { name: "surname", label: "Surname" },
                            ]}
                            colorTheme="healthcare"
                          />
                          <FormikInput
                            name={`other_names[${idx}].new_name`}
                            type="text"
                            label="Other names"
                          />
                          <FormikDatepicker
                            name={`other_names[${idx}].name_change_from`}
                            placeholderText="Select a date"
                            label="Dates from"
                            maxDate={new Date()}
                          />
                          <FormikDatepicker
                            name={`other_names[${idx}].name_change_to`}
                            placeholderText="Select a date"
                            label="Dates to"
                            maxDate={new Date()}
                          />
                        </Col2>
                        {values.other_names.length > 1 && idx !== 0 ? (
                          <SButton
                            type="button"
                            name="clear-signature"
                            onClick={() =>
                              removeRow(
                                "other_names",
                                idx,
                                values,
                                setFieldTouched,
                                setFieldValue
                              )
                            }
                            red={true}
                          >
                            Delete name
                          </SButton>
                        ) : null}
                        <hr />
                      </React.Fragment>
                    ))
                    : null}
                  <SButton
                    onClick={() =>
                      addRowName(
                        "other_names",
                        values,
                        validateForm,
                        setFieldTouched,
                        setFieldValue
                      )
                    }
                    colorTheme="healthcare"
                  >
                    Add another name
                  </SButton>
                </>
              ) : (
                ""
              )}
              <h2>Address details</h2>
              <p>
                <strong>
                  You must provide all other addresses where you have lived in
                  the last 5 years. There must be no gaps in dates, however,
                  overlapping dates are acceptable.
                </strong>
              </p>
              <h3>Current address</h3>
              <p>
                Please give details of your current address. This is the address
                to which all correspondence will be sent.
              </p>
              {values
                ? values.addresses.map((_, idx) => (
                  <React.Fragment key={`address_${idx}`}>
                    {idx === 1 ? (
                      <>
                        <h3>Other addresses</h3>
                        <p>
                          You must provide all other addresses where you have
                          lived in the last 5 years. There must be no gaps in
                          dates, however, overlapping dates are acceptable. If
                          not applicable, go to the next section.
                          </p>
                      </>
                    ) : null}
                    <FormikText
                      name={`addresses[${idx}].address`}
                      label="Address"
                    />
                    <Col2>
                      <FormikInput
                        name={`addresses[${idx}].town`}
                        type="text"
                        label="Town/City"
                      />
                      <FormikInput
                        name={`addresses[${idx}].county`}
                        type="text"
                        label="County"
                      />
                      <FormikInput
                        name={`addresses[${idx}].postcode`}
                        type="text"
                        label="Postcode"
                      />
                      <FormikInput
                        name={`addresses[${idx}].country`}
                        type="text"
                        label="Country"
                      />
                      <FormikDatepicker
                        name={`addresses[${idx}].address_from`}
                        placeholderText="Select a date"
                        label="Date from?"
                        maxDate={new Date()}
                      />
                      <FormikDatepicker
                        name={`addresses[${idx}].address_to`}
                        placeholderText="Select a date"
                        label="Date to?"
                      />
                    </Col2>
                    {values.addresses.length > 1 && idx !== 0 ? (
                      <SButton
                        type="button"
                        name="clear-signature"
                        onClick={() =>
                          removeRow(
                            "addresses",
                            idx,
                            values,
                            setFieldTouched,
                            setFieldValue
                          )
                        }
                        red={true}
                      >
                        Delete address
                      </SButton>
                    ) : null}
                    <hr />
                  </React.Fragment>
                ))
                : null}
              <SButton
                onClick={() =>
                  addRow(
                    "addresses",
                    values,
                    validateForm,
                    setFieldTouched,
                    setFieldValue
                  )
                }
                colorTheme="healthcare"
              >
                Add another address
              </SButton>
              <h2>ID Information</h2>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="national_insurance"
                  label="Do you have an national insurance number?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.national_insurance === "Yes" ? (
                  <FormikInput
                    name="national_insurance_number"
                    type="text"
                    label="National insurance number"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="national_identity_card"
                label="Do you have an national identity card (NIC)?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.national_identity_card === "Yes" ? (
                <Col2>
                  <FormikInput
                    name="nic_number"
                    type="text"
                    label="NIC number"
                  />
                  <FormikInput
                    name="nic_country"
                    type="text"
                    label="NIC country of issue"
                  />
                </Col2>
              ) : (
                ""
              )}
              <FormikTabs
                setFieldValue={setFieldValue}
                name="passport"
                label="Do you have a valid passport?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.passport === "Yes" ? (
                <Col2>
                  <FormikInput
                    name="passport_number"
                    type="text"
                    label="Passport number"
                  />
                  <FormikInput
                    name="passport_country"
                    type="text"
                    label="Passport country of issue"
                  />
                </Col2>
              ) : (
                ""
              )}
              <FormikTabs
                setFieldValue={setFieldValue}
                name="driving_licence"
                label="Do you have a driving licence?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.driving_licence === "Yes" ? (
                <Col2>
                  <FormikInput
                    name="driving_licence_number"
                    type="text"
                    label="Driving licence number"
                  />
                  <FormikInput
                    name="driving_licence_country"
                    type="text"
                    label="Driving licence country of issue"
                  />
                </Col2>
              ) : (
                ""
              )}
              <br />
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                  <Error style={{ marginTop: "1rem" }} key={e}>
                    {e}
                  </Error>
                ))
                : null}
              <SButton
                type="submit"
                disabled={isSubmitting}
                onClick={() => { }}
                colorTheme="healthcare"
              >
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default HealthcareApplication
